import React, { useEffect, useState } from "react";
import api from "../../services/api";
import logo from '../../logo.svg';
import '../../App.css';
import {Link } from 'react-router-dom';
import {SiteLogo,SearchBar} from '../../components';

function FormularioLogin({data}) {
    const [value, setValue] = useState('');
    const [pholder, setPlaceholder] = useState('default');
    const [tipo, setType] = useState('text');

    // setPlaceholder(data['pholder']);
    //setValue(data['value']);
    let input = (<>
            <input 
                type={tipo}
                onChange={()=>{
                    
                }}
                value={value}
                placeholder={pholder}
            /> 
        </>);
    ;
    useEffect(()=>{
        setType(data.tipo);
        setPlaceholder(data.pholder);
        setValue(data.value);
    },[])

    return(<>
        <input 
            type={tipo}
            onChange={()=>{}}
            value={value}
            placeholder={pholder}
        /> 
    </>);
}

export default FormularioLogin;