import React, { useEffect, useState } from "react";
import api from "../../services/api";
import logo from '../../logo.svg';
import '../../App.css';
import './SearchBar.css';
import { faCommentsDollar, faSearch} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function SearchBar() {
    const [search, setSearch] = useState();
    return (
        <div className="comp-searchBar">
           <input
                type="text"
                className="Search-Bar" 
                alt="Serach bar" 
                value={search}
                onChange={(e)=>{
                    
                    setSearch(e.target.value);
                }}
                placeholder="Search..."
                
            />
            <FontAwesomeIcon 
                className="fa-search-icon" 
                icon={faSearch} 
                onClick={()=>{
                 
                }}
            />
            
        </div>
      
    );
  }
  
  export default SearchBar;