import React, { useEffect, useState } from "react";
import api from "../../services/api";
import {Header,Footer} from '../../components';
import './Promotores.css';
import Select , { components } from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import {Link } from 'react-router-dom';
import Iframe from 'react-iframe'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {isMobile } from 'react-device-detect';
function Promotores() {
    const [promotores,setPromotores] = useState([])
 
    useEffect( () => {
          let url = '/promotores.php?function=getPromotores'
          api
          .get(url)
          .then((response) =>{ 
                console.log(response)
                if(response.data.content.length > 0){
                    setPromotores(response.data.content );
                    //console.log(response.data);
                
                }
            })
          .catch((err) => {
              console.error("ops! ocorreu um erro" + err);
          });
    
      
    }, []);
    const CarrouselJs = (props) =>{
        return (<><Carousel
            autoPlay={true}
            infiniteLoop={true}
            interval={5000}
            showArrows={true}
            dynamicHeight={true}
            stopOnHover={true}
            showThumbs={false}
            
        
        >
            <div>
                <img src={isMobile?"images/banner-mobile-home-002.png":"images/banner-home-002.jpeg"} height={isMobile?500:400} />
                
            </div>
        </Carousel></>)
    }
    return (
        <div className="pages-promotor">
            <Header noLogin={true} />
            <div className="promotor-section-1 promotor-section">
                <CarrouselJs />
                
            </div>
            <div className="promotor-section-0">
                <span>Contacta a alguno de nuestros promotores</span>
                <span>para adquirir tu carton.</span>
            </div>
            <div className="promotor-section-2 promotor-section">
                

                    {promotores.map((e)=>{
                        if(e.telefono != '' && e.telefono != null){
                            let telefono = ''
                            if(e.telefono.includes('+58')){
                                telefono = e.telefono.replaceAll('+58','0')
                            }else {
                                telefono = e.telefono
                            }
                            let Whatsapp = `https://wa.me/${e.telefono}`
                            return(<><div className="lista-promotor">
                                <div className='contacto'>
                                    <h5>{e.ciudad}</h5>
                                    <button onClick={()=>{
                                        window.location.href = Whatsapp;
                                    }}> <span>{e.nombre} -</span> <span>- {telefono} </span></button>
                                </div>
                                </div>
                            </>)
                        }
                    })}
                
            </div>
            <Footer noLogin={true} />
        </div>
      
    );
  }
  export default Promotores;
