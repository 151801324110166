import React, { useEffect, useState } from "react";
import api from "../../services/api";
import logo from '../../logo.svg';
import '../../App.css';
import './header.css';
import {Link } from 'react-router-dom';
import {SiteLogo,SearchBar} from '../../components';
import { faHouse,faBell,faRandom,faPhone} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from 'react-device-detect';
function Header({noLogin,loginMode,registerMode}) {
    const [user, setUser] = useState();
    const MenuMobile = ()=>{
                
        return (<>
        <nav className="menu-link-header-mobile">
            <Link key={'link001'} className="main-menu-link" to="/" >
                
                <span>Inicio</span>
            </Link>
            <Link key={'link002'} className="main-menu-link" to="/sorteo" >
                
                <span>Sorteo</span>
            </Link>
            <Link key={'link003'} className="main-menu-link" to="/promotores" >
               
                <span>Promotores</span>
            </Link>
        </nav>
        </>
        );
        
    }
    const Menu = ()=>{
                
            return noLogin?(<>
            <nav className="menu-link-header">
                <Link key={'link001'} className="main-menu-link" to="/" >
                    <FontAwesomeIcon icon={faHouse} />
                    <span>Inicio</span>
                </Link>
                <Link key={'link004'} className="main-menu-link" to="/sorteo" >
                    <FontAwesomeIcon icon={faRandom} />
                    <span>Sorteo</span>
                </Link>
                <Link key={'link005'} className="main-menu-link" to="/promotores" >
                    <FontAwesomeIcon icon={faPhone} />
                    <span>Promotores</span>
                </Link>
            </nav>
            </>
            ):'';
        
    }

    const Logued = ()=>{
        if(noLogin){
            return(<>
            <div className="div-right-header">
                <SearchBar /> <br />
                <FontAwesomeIcon className="icon-notification" icon={faBell} />
                <br />
                <div className="div-login">
                    <Link key={'link004'} className="main-menu-link link-login" to="/Login"  >
                        <span>LOGIN</span>
                    </Link>
                    <span className="link-separator">|</span>
                    <Link key={'link005'} className="main-menu-link link-register" to="/Register"  >
                        <span>REGISTRAR</span>
                    </Link>
                </div>
            </div>
            </>)
        }
    }
    const LoginStatus = ()=>{
       
        if(loginMode){
            return(<>
            <div className="div-right-header">
                <div className="div-login">
                <Link key={'link004'} className="main-menu-link link-login" to="/"  >
                        <span>VOLVER A INICIO</span>
                    </Link>
                    <span className="link-separator">|</span>
                    <Link key={'link005'} className="main-menu-link link-register" to="/Register"  >
                        <span>REGISTRAR</span>
                    </Link>
                </div>
            </div>
            </>)
        }
        if(registerMode){
            return(<>
            <div className="div-right-header">
                <div className="div-login">
                    <Link key={'link004'} className="main-menu-link link-login" to="/"  >
                        <span>VOLVER A INICIO</span>
                    </Link>
                    <span className="link-separator">|</span>
                    <Link key={'link005'} className="main-menu-link link-login" to="/Login"  >
                        <span>LOGIN</span>
                    </Link>
                </div>
            </div>
            </>)
        }
    }
    return (
        <div  className="comp-header" >
            <Link key={'link000'} className="main-menu-link link-logo" to="/" >
                <SiteLogo />
            </Link>
            {isMobile?<MenuMobile />:<Menu />}
            <Logued />
            <LoginStatus />
        </div>
      
    );
  }
  
  export default Header;