import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import {Home,Login,Register,Sorteo,Promotores} from './pages';
import api from "./services/api";
function App() {
  const [user, setUser] = useState();
const elemento = () =>{
  let ruta = window.location.href;
  if(ruta.includes('/sorteo')){
    return (<Sorteo />)
  } else if (ruta.includes('/register')){
    return (<Register />)
  } else if (ruta.includes('/login')){
    return (<Login />)
  } else if (ruta.includes('/promotores')){
    return (<Promotores />)
  } else {
    return (<Home />)
  }


}
  
  return (<BrowserRouter>
      <Routes>
        
        <Route path="*" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="sorteo" element={<Sorteo />} />
        <Route path="promotores" element={<Promotores />} />
        
        
      </Routes>
  </BrowserRouter>
  );
}

export default App;
