import React, { useEffect, useState } from "react";
import api from "../../services/api";
import {Header,Footer} from '../../components';
import './home.css';
import Select , { components } from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faStar} from "@fortawesome/free-solid-svg-icons";
import {Link } from 'react-router-dom';
import Iframe from 'react-iframe';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { isMobile } from 'react-device-detect';
function Home() {
    const [config, setConfig] = useState([]);
    const [link, setLink] = useState();
    const [sorteoActual, setSorteoActual] = useState();
    const [premio,setPremio] = useState();
    const [dia, setDia] = useState();

    useEffect( () => {
        let url = '/getsorteo/'
          api
          .get(url)
          .then((response) =>{ 
                
                setDia(response.data.dia_semana);
                setLink(response.data.url_youtube_actual);
                setSorteoActual(response.data.proximo_sorteo);
                setPremio({
                    diagonal:response.data.premio_diagonal,
                    esquinas:response.data.premio_esquinas,
                    lleno:response.data.premio_lleno})
              console.log(response.data.url_youtube_actual);
              setConfig(response.data);
             })
          .catch((err) => {
              console.error("ops! ocorreu um erro" + err);
          });
        const interval = setInterval(() => {
          let url = '/getsorteo/'
          api
          .get(url)
          .then((response) =>{ 
                
                setDia(response.data.dia_semana);
                setLink(response.data.url_youtube_actual);
                setSorteoActual(response.data.proximo_sorteo);
                setPremio({
                    diagonal:response.data.premio_diagonal,
                    esquinas:response.data.premio_esquinas,
                    lleno:response.data.premio_lleno})
              console.log(response.data.url_youtube_actual);
              setConfig(response.data);
             })
          .catch((err) => {
              console.error("ops! ocorreu um erro" + err);
          });
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    const CarrouselJs = (props) =>{
        return (<><Carousel
            autoPlay={false}
            infiniteLoop={true}
            interval={5000}
            showArrows={true}
            dynamicHeight={true}
            stopOnHover={true}
            showThumbs={false}
        
        >
            <div>
                <img src={isMobile?"images/banner-mobile-home-001.png":"images/banner-home-001.jpeg"} height={isMobile?500:400} />
                <div className="sorteo">
                    <h2>{config?.proximo_sorteo?.[0]}<span>{config?.proximo_sorteo?.[1]}{config?.proximo_sorteo?.[2]}{config?.proximo_sorteo?.[3]}{config?.proximo_sorteo?.[4]}</span>{config?.proximo_sorteo?.[5]}{config?.proximo_sorteo?.[6]}{config?.proximo_sorteo?.[7]}{config?.proximo_sorteo?.[8]}{config?.proximo_sorteo?.[9]}{config?.proximo_sorteo?.[10]}{config?.proximo_sorteo?.[11]}{config?.proximo_sorteo?.[12]}</h2>
                </div> 
                <div className="premios">
                    <span>{premio?.esquinas}$ 4 esquinas</span>
                    <span>{premio?.diagonal}$ diagonal</span>
                    <span>{premio?.lleno}$ carton lleno</span>
                    <span>{premio?.esquinas}$ 4 esquinas</span>
                    <span>{premio?.diagonal}$ diagonal</span>
                    <span>{premio?.lleno}$ carton lleno</span>
                </div> 
                <div className="fecha">
                    <span>{dia}</span>
                    <span>6:30 pm (GMT-4)</span>
                    <a href="https://youtube.com/channels/bingo_ve"><span>@Bingo_ve</span></a> 
                    
                </div>
            </div>
            <div>
                <img src={isMobile?"images/banner-mobile-home-002.png":"images/banner-home-002.jpeg"} height={isMobile?500:400} />
                
            </div>
            <div>
                <img src={isMobile?"images/banner-mobile-home-003.png":"images/banner-home-003.jpeg"} height={isMobile?500:400}/>
               
            </div>
        </Carousel></>)
    }
    return (
        <div className="pages-home">
            <Header noLogin={true} />
            <div className="home-section-1 home-section">
                <CarrouselJs 
                    
                    
                />
            </div>
            <div className="home-section-0">
                <Link key={'link005'} className="" >
                    <button 
                    className="ver-cartones-disponibles"
                    onClick={()=>{
                        window.location.href = 'https://bingove.com/cartones'
                    }}>
                        
                            <span>CARTONES DISPONIBLES</span>
                    
                    </button>
                </Link>
                <Link key={'link005'} className="" to="/promotores">
                    <button 
                    className="ver-cartones-disponibles"
                    onClick={()=>{
                        //window.location.href = '/promotores'
                    }}>
                        
                            <span>PROMOTORES DE VENTA</span>
                    
                    </button>
                </Link>
                <Link key={'link005'} className="" to="/sorteo"  >
                    <button
                    className="ver-cartones-disponibles"
                    >
                        
                            <span>SORTEO EN VIVO</span>
                        
                    </button>
                </Link>
            </div>
            <div className="home-section-2 home-section">
                <Iframe 
                    width={isMobile?"380":"560"} 
                    height={isMobile?"200":"315"}  
                    src="https://www.youtube.com/embed/T9hhK4TbEPk" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen 
                />
                <Iframe 
                    width={isMobile?"380":"560"} 
                    height={isMobile?"200":"315"}   
                    src="https://www.youtube.com/embed/RI1Liy3z7KU" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen 
                />
            </div>
            <Footer noLogin={true} />
        </div>
      
    );
  }
  export default Home;

  /*  
 <div className="home-section-1 home-section">
                <div className="premios">
                    <span>500$</span>
                </div>
                <div className="carrousel">
                    <Link key={'link005'} className="" to="/sorteo"  >
                        <img src="images/sorteo-14-05-2023.jpg" height={400} alt="" />
                    </Link>
                    <img src="images/verificar-carton.jpg" height={400} alt="" />
                    <img src="images/banner-1.jpg" height={400} alt="" />
                </div>
            </div>
  */