import React, { useEffect, useState } from "react";
import api from "../../services/api";
import logo from '../../logo.svg';
import {SiteLogo} from '../../components'
import '../../App.css';
import './footer.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from "@fortawesome/free-solid-svg-icons";
function Footer() {
    const [user, setUser] = useState();
    return (
        <>
            <div id="footer">
                <div className="footer-row row1">
                    <div className="footer-column column-1">
                        <div className="footer-inner-row">
                            <SiteLogo />
                        </div>
                        <div className="footer-inner-row">
                            <span>Prueba tu #SUERTE</span>
                        </div>
                    </div>
                    <div className="footer-column column-2">
                        <div className="footer-inner-row">
                            <span>LEGAL</span>
                        </div>
                        <div className="footer-inner-row">
                            <span>Teminos y condiciones</span>
                            <span>Politica de cookies</span>
                        </div>
                    </div>
                    <div className="footer-column column-3">
                        <div className="footer-inner-row">
                            <span>SOCIAL MEDIA</span>
                        </div>
                        <div className="footer-inner-row social-media">
                            <button><FontAwesomeIcon icon={faStar} /></button>
                            <button><FontAwesomeIcon icon={faStar} /></button>
                            <button><FontAwesomeIcon icon={faStar} /></button>
                        </div>
                    </div>
                    <div className="footer-column column-4">
                        <div className="footer-inner-row">
                            <span>DOWNLOAD</span>
                        </div>
                        <div className="footer-inner-row">
                            <button><FontAwesomeIcon icon={faStar} /></button>
                            <button><FontAwesomeIcon icon={faStar} /></button>
                        </div>
                    </div>
                </div>
                <div className="footer-row row2">
                    <span>© 2023 URMAH DEVELOPMENTS All Rights Reserved.</span>
                </div>
            </div>
        </>
      
    );
  }
  
  export default Footer;