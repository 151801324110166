import React, { useEffect, useState } from "react";
import api from "../../services/api";
import '../../App.css';
import './Carton.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrash,faStar} from "@fortawesome/free-solid-svg-icons";

function Carton({Bolas,CartonesSaved,SaveCarton,Cartones,Sorteo}) {
    const [visible, setVisible] = useState("comp-carton");    
    
    const Marcar2 = (props) =>{
        let bolasStr = props.numeros;
        bolasStr = bolasStr.replaceAll('\[','');
        bolasStr = bolasStr.replaceAll('\]','');
        let bolasArr = bolasStr.split(",");
        let template = <></>;
        let status = [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false];        
        let Ganador = true;
        for(let x = 0 ; x < status.length;x++){
            if(status[x] == false){
                x = 100;
                Ganador = false;
            }
        }
        for (let index = 0;index < Bolas.length;index++){            
            if(bolasArr[0].toString()==Bolas[index].toString()){status[0]=true}
            if(bolasArr[1].toString()==Bolas[index].toString()){status[1]=true}
            if(bolasArr[2].toString()==Bolas[index].toString()){status[2]=true}
            if(bolasArr[3].toString()==Bolas[index].toString()){status[3]=true}
            if(bolasArr[4].toString()==Bolas[index].toString()){status[4]=true}
            if(bolasArr[5].toString()==Bolas[index].toString()){status[5]=true}
            if(bolasArr[6].toString()==Bolas[index].toString()){status[6]=true}
            if(bolasArr[7].toString()==Bolas[index].toString()){status[7]=true}
            if(bolasArr[8].toString()==Bolas[index].toString()){status[8]=true}
            if(bolasArr[9].toString()==Bolas[index].toString()){status[9]=true}
            if(bolasArr[10].toString()==Bolas[index].toString()){status[10]=true}
            if(bolasArr[11].toString()==Bolas[index].toString()){status[11]=true}
            status[12]=true;
            if(bolasArr[13].toString()==Bolas[index].toString()){status[13]=true}
            if(bolasArr[14].toString()==Bolas[index].toString()){status[14]=true}
            if(bolasArr[15].toString()==Bolas[index].toString()){status[15]=true}
            if(bolasArr[16].toString()==Bolas[index].toString()){status[16]=true}
            if(bolasArr[17].toString()==Bolas[index].toString()){status[17]=true}
            if(bolasArr[18].toString()==Bolas[index].toString()){status[18]=true}
            if(bolasArr[19].toString()==Bolas[index].toString()){status[19]=true}
            if(bolasArr[20].toString()==Bolas[index].toString()){status[20]=true}
            if(bolasArr[21].toString()==Bolas[index].toString()){status[21]=true}
            if(bolasArr[22].toString()==Bolas[index].toString()){status[22]=true}
            if(bolasArr[23].toString()==Bolas[index].toString()){status[23]=true}
            if(bolasArr[24].toString()==Bolas[index].toString()){status[24]=true}
            template = (<>
                <div className={Ganador?"carton-row carton-row-1 winner":"carton-row carton-row-1"}>
                    <div className="carton-column carton-column-b" data-salio={status[1-1]}>{bolasArr[1-1]}</div>
                    <div className="carton-column carton-column-i" data-salio={status[6-1]}>{bolasArr[6-1]}</div>
                    <div className="carton-column carton-column-n" data-salio={status[11-1]}>{bolasArr[11-1]}</div>
                    <div className="carton-column carton-column-g" data-salio={status[16-1]}>{bolasArr[16-1]}</div>
                    <div className="carton-column carton-column-o" data-salio={status[21-1]}>{bolasArr[21-1]}</div>
                </div>
                <div className={Ganador?"carton-row carton-row-2  winner":"carton-row carton-row-2"}>
                    <div className="carton-column carton-column-b" data-salio={status[2-1]}>{bolasArr[2-1]}</div>
                    <div className="carton-column carton-column-i" data-salio={status[7-1]}>{bolasArr[7-1]}</div>
                    <div className="carton-column carton-column-n" data-salio={status[12-1]}>{bolasArr[12-1]}</div>
                    <div className="carton-column carton-column-g" data-salio={status[17-1]}>{bolasArr[17-1]}</div>
                    <div className="carton-column carton-column-o" data-salio={status[22-1]}>{bolasArr[22-1]}</div>
                </div>
                <div className={Ganador?"carton-row carton-row-3  winner":"carton-row carton-row-3"}>
                    <div className="carton-column carton-column-b" data-salio={status[3-1]}>{bolasArr[3-1]}</div>
                    <div className="carton-column carton-column-i" data-salio={status[8-1]}>{bolasArr[8-1]}</div>
                    <div className="carton-column carton-column-n" data-salio={true}></div>
                    <div className="carton-column carton-column-g" data-salio={status[18-1]}>{bolasArr[18-1]}</div>
                    <div className="carton-column carton-column-o" data-salio={status[23-1]}>{bolasArr[23-1]}</div>
                </div>
                <div className={Ganador?"carton-row carton-row-4  winner":"carton-row carton-row-4"}>
                    <div className="carton-column carton-column-b" data-salio={status[4-1]}>{bolasArr[4-1]}</div>
                    <div className="carton-column carton-column-i" data-salio={status[9-1]}>{bolasArr[9-1]}</div>
                    <div className="carton-column carton-column-n" data-salio={status[14-1]}>{bolasArr[14-1]}</div>
                    <div className="carton-column carton-column-g" data-salio={status[19-1]}>{bolasArr[19-1]}</div>
                    <div className="carton-column carton-column-o" data-salio={status[24-1]}>{bolasArr[24-1]}</div>
                </div>
                <div className={Ganador?"carton-row carton-row-5  winner":"carton-row carton-row-5"}>
                    <div className="carton-column carton-column-b" data-salio={status[5-1]}>{bolasArr[5-1]}</div>
                    <div className="carton-column carton-column-i" data-salio={status[10-1]}>{bolasArr[10-1]}</div>
                    <div className="carton-column carton-column-n" data-salio={status[15-1]}>{bolasArr[15-1]}</div>
                    <div className="carton-column carton-column-g" data-salio={status[20-1]}>{bolasArr[20-1]}</div>
                    <div className="carton-column carton-column-o" data-salio={status[25-1]}>{bolasArr[25-1]}</div>
                </div>
                
                </>)
        }
        
        return template;
    }
    
    const LoadCartones = ()=>{        
        return Cartones?.map((e)=>{ 
            return CartonesSaved.map((numero)=>{
                const serial = e?.serial.replace(Sorteo,"")
                if(parseInt(serial) == parseInt(numero)){
                    return (
                    <CartonesAll 
                        key={'carton-serial-'+serial}
                        datos={
                            {
                                serial:e?.serial.replace(Sorteo,""),
                                dueno:e?.nombre_cliente,
                                numeros:e?.datos
                            }
                        }
                    />)
                }
            })
        })
        
    }
    
    const DeleteCarton = (nro) =>{
        SaveCarton(CartonesSaved.filter((a)=> parseInt(a) != parseInt(nro)))
    } 
    const CartonesAll = ({datos}) =>{
        return (
            <div  className={visible} >
                <div className="carton">
                    <div className="carton-header">
                        <div className="eliminar-carton">
                            
                        </div>
                        <div className="carton-serial">{datos.serial}</div>
                        <div className="eliminar-carton">
                            <FontAwesomeIcon 
                                icon={faTrash} 
                                onClick={()=>DeleteCarton(datos.serial)}
                            />
                        </div>
                    </div>
                    <div className="carton-header">
                        <div className="carton-tittle">B</div>
                        <div className="carton-tittle">I</div>
                        <div className="carton-tittle">N</div>
                        <div className="carton-tittle">G</div>
                        <div className="carton-tittle">O</div>
                    </div>
                    <div className="carton-content">
                        <Marcar2 numeros={datos.numeros}/>
                    </div>
                    <div className="carton-footer">
                        <div className="carton-name">{datos.dueno}</div>
                    </div>
                </div>
            </div>
          
        );

    }
    return (<>
    {LoadCartones()}
    </>);
  }
  
  export default Carton;
