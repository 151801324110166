import React, { useEffect, useState } from "react";
import api from "../../services/api";
import '../../App.css';
import './Tablero.css';
import Iframe from 'react-iframe'
function Tablero({datos,funcion,Bolas}) {
    

    const Salieron=()=>{
        return Bolas
            .slice(0)
            .reverse()
            .map(( e, i)=>
            (<div className={i == 0?"bolita ultima":"bolita "} data-number={e} >
                {e}
            </div>)
        )        
    }
    return (
        <div  className="comp-tablero" >
               <div className="tabla">
                    <Salieron />
               </div>
        </div>
      
    );
  }
  
  export default React.memo(Tablero);