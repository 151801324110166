import React, { useCallback, useEffect, useRef, useState } from "react";
import api from "../../services/api";
import {Header,Footer,Youtube,Carton,Tablero,Chat} from '../../components';
import './Sorteo.css';
import Select , { components } from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faStar} from "@fortawesome/free-solid-svg-icons";

function Sorteo() {
    const CARTONSAVED = window.localStorage.getItem('cartonesSaved')
    const [Cartones,setCartones] = useState([]);
    const [serialToAdd,setSerialToAdd] = useState();    
    const [link, setLink] = useState("");
    const [sorteoActual, setSorteoActual] = useState("");    
    const [premioDiagonal,setPremioDiagonal] = useState(0);
    const [premioEsquinas,setPremioEsquinas] = useState(0);
    const [premioLleno,setPremioLleno] = useState(0);
    const [AppVersion,setAppVersion] = useState(0);
    const [Configs,setConfigs] = useState([]);
    const [DiaSorteo,setDiaSorteo] = useState(0);
    const [Promotores,setPromotores] = useState(0);
    const [rondas,setRondas] = useState(0);
    const [rondaActual,setRondaActual] = useState(3);
    const [Ganadores_1,setGanadores_1] = useState([]);
    const [Ganadores_2,setGanadores_2] = useState([]);
    const [Ganadores_3,setGanadores_3] = useState([]);
    const [CartonesLocal,setCartonesLocal] = useState([])

    const [BolasSorteo,setBolasSorteo] = useState([]);
    const input_add = useRef(null)

    const GetCartones = (sorteo)=>{
        api.get('/cartones_all/' + sorteo)
        .then((response) =>{ 
            if(response?.data?.content != undefined 
                && response?.data?.content != null 
                && response?.data?.content != "")
            {
                console.log(response?.data?.content)
                setCartones(response?.data?.content)
            }
        })
        .catch((err) =>  console.error( err))
    }
    const GetBolasSorteo = () =>{
        api.get('/bolas_sorteo/')
        .then((response) =>{ 
            if(response?.data != undefined && response?.data != null && response?.data != ""){
                setBolasSorteo(response.data[0].split(","));
            }
        })
        .catch((err) => console.error( err));
    }
    const GetConfiguraciones = async () =>{
        api.get('/getsorteo/')
            .then((response) =>{ 
                const {data} = response
                setAppVersion(data?.version_app)
                setConfigs(data)
                setDiaSorteo(data?.dia_semana)
                setPromotores(data?.act_promotores)
                setPremioDiagonal(data?.premio_diagonal)
                setPremioEsquinas(data?.premio_esquinas)
                setPremioLleno(data?.premio_lleno)
                setLink(data?.url_youtube_actual);
                setSorteoActual(data?.proximo_sorteo);
                setRondas(data?.rondas);
            })
            .catch((err) => console.error(err)
        )  
    }
    const GetGanadores = (ronda) =>{
        api.get('/ganadores/'+ronda)
        .then((response) =>{ 
            if(response?.data != undefined && response?.data != null && response?.data != ""){
                const GanadoresList = response?.data.split("-")
                //console.log(response)
                switch(ronda){
                    case 1:setGanadores_1(GanadoresList);break;
                    case 2:setGanadores_2(GanadoresList);break;
                    case 3:setGanadores_3(GanadoresList);break;
                    default:break;
                }                
            }
        })
        .catch((err) => console.error(err))
    }
    const GetRondaGanadores = () =>{
        //reducirlas a una llamada
        GetGanadores(1)
        if(rondaActual > 1)GetGanadores(2)
        if(rondaActual > 2)GetGanadores(3)
    }
    useEffect( () => {    
        if(CARTONSAVED != undefined && CARTONSAVED != null){
            setCartonesLocal(CARTONSAVED.split(","))
        }
        GetConfiguraciones()   
        
        GetBolasSorteo()  
        const interval = setInterval(()=>{
            GetBolasSorteo()  
        }, 10000);
        return () => clearInterval(interval);
    }, []);
    useEffect( () => {        
        GetRondaGanadores()
        const interval = setInterval(()=>{ 
            GetRondaGanadores()
        }, 20000);
        return () => clearInterval(interval);
    }, []);  
    useEffect( () => {        
        GetCartones(sorteoActual)
    }, [sorteoActual]);  

    const TablaLateral = () =>{
        return(
            <div className="div-tabla-detalles detalles">
                <img  src="./images/bingo-logo.png" width={150} height={80} alt="" />
                <span>Version{AppVersion}</span>
                <div className="nro-sorteo">SORTEO: <b>{sorteoActual}</b></div>
                <b>{DiaSorteo}</b>
                <span>{rondas} Rondas de premios</span>                
                <div className="div-premios">
                    <div className="tabla-premios">
                        <ul>
                            <li>DIAGONAL: {premioDiagonal}$</li>
                            <li>4 ESQUINAS: {premioEsquinas}$</li>
                            <li>CARTON LLENO: {premioLleno}$</li>
                        </ul>
                    </div>
                </div>
                {Promotores==0 && <b style={{color:"red"}}>{"VENTAS CERRADAS"}</b>}
                {Promotores==1 && <b style={{color:"green"}}>{"VENTAS ABIERTAS"}</b>}
            </div>
        )
    }
    const TablaGanadores= () =>{
        return(<><div className="div-tabla-detalles ganadores">
            <div className="nro-sorteo">SORTEO: <b>{sorteoActual}</b></div>
            <div className="div-ganadores">
                <span className="ganador-tittle"><b>LISTA DE GANADORES</b></span>                
                <div className="tabla-ganadores">
                    {rondaActual > 0 && 
                        (<>
                            <span className="ronda-actual"><b>RONDA {1}</b></span><br />
                            {Ganadores_1?.map((ganador,index)=>(<div key={'ganador-'+index}><span>{index + 1 } : {ganador}</span><br /></div>))}
                        </>)
                    }
                    {rondaActual > 1 && 
                        (<>
                            <span className="ronda-actual"><b>RONDA {2}</b></span><br />
                            {Ganadores_2?.map((ganador,index)=>(<div key={'ganador-'+index}><span>{index + 1 } : {ganador}</span><br /></div>))}
                        </>)
                    }
                    {rondaActual > 2 && 
                        (<>
                            <span className="ronda-actual"><b>RONDA {3}</b></span><br />
                            {Ganadores_3?.map((ganador,index)=>(<div key={'ganador-'+index}><span>{index + 1 } : {ganador}</span><br /></div>))}
                        </>)
                    }
                </div>
            </div>
        </div>
        </>)
    }

    const handleChange = ()=>{
        setSerialToAdd(input_add.current.value);
    }
    const UpdateCartonesLocal = (value = []) =>{
        localStorage.setItem('cartonesSaved',value)
        setCartonesLocal(value)
    }
    const CartonAdd = () =>{
        return (<>
            <div className="add-carton">
                <span>AGREGAR CARTON</span>
                    <input 
                        ref={input_add}
                        type="number"  
                        placeholder="Serial" 
                        onChange={handleChange}
                    />
                <button 
                onClick={()=>{                   
                    let validar = false;                    
                    Cartones?.map((e)=>{
                        const serial = (e.serial).replace(sorteoActual,'')
                        if( parseInt(serial) == parseInt(serialToAdd)){
                            UpdateCartonesLocal([...CartonesLocal.filter((a)=> parseInt(a) != parseInt(serialToAdd)),serialToAdd])                              
                            validar=true;
                        }                        
                    })
                    if(!validar){
                        alert('el carton no es valido para este sorteo o no fue vendido aun');
                    } 
                }}
                >AGREGAR</button>
            </div>
        </>)
    }

    return (
        <div className="pages-sorteo">
            <Header noLogin={true} />

            <div className="page-content">
                <div className="row-1 row-principal">
                    <div className="column-1 column-principal">
                        {<TablaLateral  />}
                    </div>
                    <div className="column-2 column-principal">
                        {<Youtube link={link}/>}
                    </div>
                    <div className="column-3 column-principal">
                        {<TablaGanadores  />}
                    </div>
                </div>
                <div className="row-3 row-principal">
                    <Tablero Bolas={BolasSorteo}/>
                </div>
                <div className="row-2 row-principal">
                    {CartonAdd()}
                    <Carton 
                        Bolas={BolasSorteo}
                        CartonesSaved={CartonesLocal}
                        SaveCarton={UpdateCartonesLocal}
                        Sorteo={sorteoActual}
                        Cartones={Cartones}
                        serialToAdd={serialToAdd} 
                    />                 
                    
                </div>

            </div>
            <Footer noLogin={true} />
        </div>
      
    );
  }
  export default Sorteo;
