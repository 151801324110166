import React, { useEffect, useState } from "react";
import api from "../../services/api";
import '../../App.css';
import Iframe from 'react-iframe'
function Youtube({datos,funcion,link}) {
    
    return (
        <div  className="comp-youtube" >
                <Iframe 
                src= {"https://www.youtube.com/embed/"+link}
                width="550"
                height="300" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowfullscreen
                
               
             />
        </div>
      
    );
  }
  
  export default Youtube;