import React, { useEffect, useState } from "react";
import api from "../../services/api";
import {Header,Card, SiteLogo,FormularioLogin} from '../../components';
import '../../App.css';
import './login.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEye,faLock,faEnvelope} from "@fortawesome/free-solid-svg-icons";
function Login() {
    const [user, setUser] = useState();
    
    
        
    return (<>
        <Header loginMode={true}/>
        <div className="page-login">
            <div className="form-login">
                <div className="form-login-left">
                    <h3>Hello!</h3>
                    <div className="form-login-inputs">
                        <div className="login-input">
                            <FontAwesomeIcon className='icon-left' icon={faEnvelope} />
                            <FormularioLogin className='input-form' data={{'pholder':'Email','value':'','tipo':'text'}}/>
                            <FontAwesomeIcon className='icon-right ocultar' icon={faEye} />
                        </div>
                        <div className="login-input">
                            <FontAwesomeIcon className='icon-left' icon={faLock} />
                            <FormularioLogin className='input-form' data={{'pholder':'Password','value':'','tipo':'pass'}}/>
                            <FontAwesomeIcon className='icon-right' icon={faEye} />
                        </div>
                        
                    </div>
                    <div className="form-remember">
                        <div className="check-remember">
                            <input type="checkbox" ></input>
                            <span>Remember me</span>
                        </div>
                        <span>Forgot password?</span>
                    </div>
                    <button className="btn-login">Log in</button>
                    <span className="form-or ">OR</span>
                    <button className="btn-google ">Continue with Google</button>
                    <button className="btn-apple ">Continue with Apple</button>
                </div>
                <div className="form-login-right">
                    <SiteLogo />
                </div>
            </div>
        </div>
      
        </>);
  }
  
  export default Login;